/* eslint-disable */
import {
  MenuItemsType,
  SwapIcon,
  SwapFillIcon,
  TrophyIcon,
  NftFillIcon,
  TradeFilledIcon,
  RocketIcon,
  MoreIcon,
  TicketFillIcon,
  VoteIcon,
  VerifiedIcon
} from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { DropdownMenuItemType } from '@pancakeswap/uikit/src/components/DropdownMenu/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const filterItemBySupportChainId = (item, chainId) => {
  return !chainId || !item.supportChainIds ? true : item.supportChainIds?.includes(chainId)
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Trade'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/swap',
        showItemsOnMobile: false,
        items: [
          {
            label: t('Swap'),
            href: '/swap',
          },
          {
            label: t('Liquidity'),
            href: '/liquidity',
          },
          {
            label: "Buy BSD",
            href: "https://blockstar.zone/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ],
      },
      {
        label: 'NFTs',
        icon: NftFillIcon,
        href: "#sec",
        items: [
          {
            label: "BlockStar NFTs",
            href: "https://blockstar.site/nft",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ]
      },
      {
        label: 'Staking',
        icon: TradeFilledIcon,
        href: "#sec",
        items: [
          {
            label: "BlockStar Staking",
            href: "https://blockstar.site/staking",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ]
      },
      {
        label: 'Launchpad',
        icon: RocketIcon,
        href: "#sec",
        items: [
          {
            label: "BlockStar Launchpad",
            href: "https://launchpad.blockstar.site/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ]
      },
      {
        label: 'Portfolio',
        icon: TicketFillIcon,
        href: "#sec",
        items: [
          {
            label: "BlockStar Portfolio",
            href: "https://blockstar.site/portfolio",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ]
      },
      {
        label: 'DAO',
        icon: VoteIcon,
        href: "#sec",
        items: [
          {
            label: "BlockStar DAO",
            href: "https://blockstar.site/dao",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ]
      },
      {
        label: 'Synergy',
        icon: VerifiedIcon,
        href: "#sec",
        items: [
          {
            label: "BlockStar Synergy",
            href: "https://synergy.blockstar.site/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ]
      },
      {
        label: 'Explorer',
        icon: TrophyIcon,
        href: "#sec",
        items: [
          {
            label: "BlockStar Mainnet",
            href: "https://scan.blockstar.one/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "BlockStar Testnet",
            href: "https://testnet-scan.blockstar.one/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
        ]
      },
      {
        label: "",
        href: "#",
        icon: MoreIcon,
        items: [
          {
            label: "BlockStar Website",
            href: "https://blockstar.site/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "BlockStar Page",
            href: "https://blockstar.social/BlockStar_Official",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "BlockStar Group",
            href: "https://blockstar.social/BlockStar",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "BlockStar Chat",
            href: "https://blockstar.chat",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "X",
            href: "https://twitter.com/BlockStar_BST",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          }
        ],
      }
    ].filter((item) => filterItemBySupportChainId(item, chainId))

export default config
