import { ChainId, Token, WETH } from '@pancakeswap/sdk'

export const MAIN_TOKEN = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x1C472EfF1B5970f995A10Af5Be2d66242654282d',
    18,
    'Pump Smart Teacher Coin',
    'PST',
    'https://mms-soilminerals.com/'
  ),

  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0x19D2f834eec88899727662e75CA16ABefB5E3a06',
    18,
    'Pump Smart Teacher Coin',
    'PST',
    'https://mms-soilminerals.com/'
  )

}

export const USDT = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x55d398326f99059fF775485246999027B3197955',
    18,
    'USDT',
    'USDT',
    'https://www.binance.com/',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
    18,
    'USDT',
    'Tether USD',
    'https://www.centre.io/usdt'
  ),
}

export const BUSD = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x7729A49b9833A08598E2aEE7e1c7D7b4c4F7f822',
    18,
    'BSD',
    'BlockStar USD',
    'https://www.binance.com/',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xA8a0af1400b38401166E9D6f9e30C1Fc264c2133',
    18,
    'BSD',
    'BlockStar USD',
    'https://www.binance.com/',
  ),
}

export const WBNB = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x36032F21F411D841AE7fC61679b7aB00548b636D',
    18,
    'BST',
    'BST',
    'https://www.binance.com/',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0x1203de582668fa306FC9A4cE84F306c04De56187',
    18,
    'BNB',
    'BNB',
    'https://www.binance.com/',
  ),
}

export const MAIN_TOKEN2 = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'BUSD',
    'https://www.binance.com/',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'BUSD',
    'https://www.binance.com/',
  ),
}

export const MAIN_TOKEN3 = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x55d398326f99059fF775485246999027B3197955',
    18,
    'USDT',
    'USDT',
    'https://www.binance.com/',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
    18,
    'USDT',
    'Tether USD',
    'https://www.centre.io/usdt'
  ),
}

export const MAIN_TOKEN4 = {
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'BNB',
    'BNB',
    'https://www.binance.com/',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    18,
    'BNB',
    'BNB',
    'https://www.binance.com/',
  ),
}

export const SWAP = {
  [ChainId.BSC]: MAIN_TOKEN[ChainId.BSC],
  [ChainId.BSC_TESTNET]: MAIN_TOKEN[ChainId.BSC_TESTNET],
}

export const SWAP2 = {
  [ChainId.BSC]: USDT[ChainId.BSC],
  [ChainId.BSC_TESTNET]: USDT[ChainId.BSC_TESTNET],
}



export const bscTokens = {
  wbnb: WETH[ChainId.BSC],
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: WBNB[ChainId.BSC],
  busd : BUSD[ChainId.BSC],
  usdt : USDT[ChainId.BSC],
  bkr : MAIN_TOKEN2[ChainId.BSC],
  cake : MAIN_TOKEN[ChainId.BSC],
  mali : MAIN_TOKEN3[ChainId.BSC],
  cbn : MAIN_TOKEN4[ChainId.BSC]
}

export const bscTestnetTokens = {
  wbnb: WETH[ChainId.BSC_TESTNET],
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: WBNB[ChainId.BSC_TESTNET],
  busd : BUSD[ChainId.BSC_TESTNET],
  usdt : USDT[ChainId.BSC_TESTNET],
  bkr : MAIN_TOKEN2[ChainId.BSC_TESTNET],
  cake : MAIN_TOKEN[ChainId.BSC_TESTNET],
  mali : MAIN_TOKEN3[ChainId.BSC_TESTNET],
  cbn : MAIN_TOKEN4[ChainId.BSC_TESTNET]
}
